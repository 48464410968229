.WhatWeBring{
    position: relative;
    top: 20vh;
    height: 100%;
    padding-block-end: 100px;
}
.why-us-section {
    padding: 8px 0 8px;
}
.why-us-head {
    text-align: center;
    margin: 0 0 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.why-us-title h2 {
    color: white;
    font-size: 25px;
    letter-spacing: 0;
    line-height: 35px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.why-us-title > p {
    color: #ffffff;
    line-height: 22px;
}
.why-us-title-border {
    background:#0099ff none repeat scroll 0 0;
    border-radius: 2px;
    display: inline-block;
    height: 3px;
    position: relative;
    width: 50px;
}

.clearfix{
    display: flex;
}
.service-3 .service-box {
    display: flex;
    margin-bottom: 35px;
    padding-right: 10px;
}
.service-3 .service-box .iconset {
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.service-3 .service-box .iconset i {
    color: #ffffff;
    font-size: 44px;
}
.service-3 .service-box .why-us-content {
    float: left;
    width: 80%;
}
.service-3 .service-box .why-us-content h4 {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    margin: 14px 0 12px;
    text-transform: uppercase;
}
.service-3 .service-box .why-us-content p {
    color: #ffffff;
    font-size: 13px;
    font-weight: 300;
    line-height: 24px;
}

.icon {
    color : #0099ff;
    padding:0px;
    font-size:40px;
    border: 1px solid #0099ff;
    border-radius: 100px;
    color: #0099ff;
    font-size: 28px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    width: 70px;
}

.subheader{
    color: white;
}

@media (max-width: 600px) {

    .clearfix {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block-start: 15px;
    }

.service-3 .service-box .why-us-content {
    float: left;
    width: 80%;
    text-align: center;
}
}