.card {
  width: 275px;
  height: 325px;
  border-radius: 20px;
  background: #ffffff;
  position: relative;
  padding: 1.8rem;
  border: 2px solid #c3c6ce;
  transition: 0.4s ease-out;
  overflow: visible;
  text-align: center;
  -webkit-transition: 0.4s ease-out;
  -moz-transition: 0.4s ease-out;
  -ms-transition: 0.4s ease-out;
  -o-transition: 0.4s ease-out;
}

 .hr1{
  width: 50px;
  margin-left: 40%
 }
 
 .card-details {
  color: black;
  height: 100%;
  gap: .5em;
  display: grid;
  place-content: center;
 }
 
 .card-button {
  display:flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, 125%);
  width: 70%;
  height: 15%;
  border-radius: 1rem;
  border: none;
  background-color: #008bf8;
  color: #ffffff;
  font-size: 1rem;
  padding: .5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  text-decoration:none;
  justify-content: center;
  opacity: 0;
  display: none;
  transition: 0.2s ease-out;
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  -ms-transition: 0.2s ease-out;
  -o-transition: 0.2s ease-out;
}
 
 .text-body {
  color: rgb(120, 117, 117);
  font-size: 1.1rem;
 }
 
 /*Text*/
 .text-title {
  font-size: 2rem;
  font-weight: bold;
 }
 
 /*Hover*/
 .card:hover {
  border-color: #008bf8;
  height: 335px;
  width: 285px;
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
 }
 
 .card:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
  display: flex;
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
}