.hero-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 90vh;
    background-color: rgba(0, 0, 0, 0.888);
    position: relative;
    top: 10vh;
    width: 100vw;
}

.home-wrapper{
padding-bottom: 75px;
}

.content{
    position: absolute;
    top: 10%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
}

@media(max-width: 768px){
    .content{
        top: 15%;
    }
}

.hero-title{
    font-size: 4rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
}

.home-link{
    text-decoration: none;
}

.hero-h2 {
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
    color: #fff;
    padding-left: 25px;
    padding-right: 25px;
    line-height: 40px;
}

.svg{
    position: absolute;
    bottom: 0;
}


.hero-h3{
    opacity: 1;
    transform: none;
    color: white;
    text-align: center;
    font-size: 1.5rem;
    padding-block-start: 30px;
    padding-inline: 15px;
    font-weight: 100;
    font-style: italic;
  }

.victim-link{
    color: rgb(255, 235, 197);
    text-decoration: none;
}

.victim-link:hover{
    color: #7fcbfe;
    text-decoration:dotted;
}

@media only screen and (max-width: 767px) {
   .content{
    justify-self: center;
    justify-content: center;
   }
   
    .hero-title {
        font-size: 3.5rem;
    }

    .hero-h2 {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }

    .svg{
        width: 100vw;
        height: 95px;
    }

    .hero-h3{
        font-size: 1.25rem;
    }
}

.hero-title:first-letter {
    color: #0099ff;
  }

  .button {
    display: flex;
    justify-content: center;
    height: 40px;
    position: relative;
    padding: 0 20px;
    font-size: 18px;
    text-transform: uppercase;
    border: 0;
    box-shadow: hsl(210deg 87% 36%) 0px 7px 0px 0px;
    background-color: hsl(210deg 100% 44%);
    border-radius: 12px;
    overflow: hidden;
    transition: 31ms cubic-bezier(.5, .7, .4, 1);
    width: 165px;
    align-items: center;
    align-self: center;
    position: relative;
    top: 10px;
   }

   
   
   .button:before {
    content: attr(alt);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    font-size: 15px;
    font-weight: bold;
    color: white;
    letter-spacing: 4px;
    opacity: 1;
   }
   
   .button:active {
    box-shadow: none;
    transform: translateY(7px);
    transition: 35ms cubic-bezier(.5, .7, .4, 1);
   }
   
   .button:hover:before {
    transition: all .0s;
    transform: translateY(100%);
    opacity: 0;
}
   
   .button i {
    color: white;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 4px;
    font-style: normal;
    transition: all 2s ease;
    transform: translateY(-20px);
    opacity: 0;
   }
   
   .button:hover i {
    transition: all .2s ease;
    transform: translateY(0px);
    opacity: 1;
   }
   
   .button:hover i:nth-child(1) {
    transition-delay: 0.045s;
   }
   
   .button:hover i:nth-child(2) {
    transition-delay: calc(0.045s * 3);
   }
   
   .button:hover i:nth-child(3) {
    transition-delay: calc(0.045s * 4);
   }
   
   .button:hover i:nth-child(4) {
    transition-delay: calc(0.045s * 5);
   }
   
   .button:hover i:nth-child(6) {
    transition-delay: calc(0.045s * 6);
   }
   
   .button:hover i:nth-child(7) {
    transition-delay: calc(0.045s * 7);
   }
   
   .button:hover i:nth-child(8) {
    transition-delay: calc(0.045s * 8);
   }
   
   .button:hover i:nth-child(9) {
    transition-delay: calc(0.045s * 9);
   }
   
   .button:hover i:nth-child(10) {
    transition-delay: calc(0.045s * 10);
   }

   @keyframes wave {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }