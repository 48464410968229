.blogPage{
    position: relative;
    top: 10vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
}

.featuredandblogs{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 25px;
    flex-wrap: wrap;
}