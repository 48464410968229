*::-webkit-scrollbar {
  width: 5px;
  height:0px;
  background-color: white;
  }
  
*::-webkit-scrollbar-thumb {
    background-color: #0099ff;
    border-radius:5px  ;
    -webkit-border-radius:5px  ;
    -moz-border-radius:5px  ;
    -ms-border-radius:5px  ;
    -o-border-radius:5px  ;
}

.recentPostWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
}

.recentPostWrapperHeading{
    align-self: center;
    padding-block-start: 10px;
    font-size: xx-large;
}

.postWrapper{
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100vw;
    overflow-x: scroll;
    padding-inline: 15px;
    padding-block: 10px;
    
}

  


.recentPost{
    width: 325px;
    flex-basis: 325px;
    flex-shrink: 0;
    justify-content: space-evenly;
    height: 215px;
    padding-top: 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: 0.3s ease-out;
    -webkit-transition: 0.3s ease-out;
    -moz-transition: 0.3s ease-out;
    -ms-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
}

.recentPost:hover{
    scale: 103%;
}

.bg-image{
    z-index: -1;
    height: 100%;
    width: 100%;
}

.recentPost {
    position: relative;
    overflow: hidden;
}
  
  .bg-image {
    filter: brightness(50%);
    z-index: -1;
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    -webkit-filter: brightness(50%);
}
  
  .recentPostHeading{
    position: absolute;
    top: 50%; /* Center the text vertically */
    left: 50%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Center the text precisely */
    z-index: 1; /* Place the text on top of the image */
    color: white; /* Set the text color */
    text-shadow: 0 0 10px black; /* Add a shadow effect to the text */
    text-align: center; /* Center the text horizontally */
    width: 90%;
    padding: 5px;
}
  
