@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");

:root {
	--mainColor: #1d1d1e;
	--mainColorLight: #1c202f93;
	--secondaryColor: #69c3ff;
	--textColor: wheat;
}

.navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 10vh;
	padding: 0 2rem;
	background-color: rgba(0, 0, 0, 0.916);
	color: var(--textColor);
	
}
.navbar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10; 
  }

.Links {
	padding: 2px;
	margin: 0 1rem;
	color: white;
	text-decoration: none;
}

.Links:hover {

	scale: 1.1;

}

.active{
	color:burlywood;
}

header .nav-btn {
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
  text-align: center;
  position: relative;

  top: 4px
}

header div,
nav {
  
display: flex;
	align-items: center;
  position: absolute;
  right:0px;
  font-size: 1.25rem;
}

.header h3{
	padding-left: 10px;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
		position: relative;
		
	}

	header nav {
		z-index: 9;
		position: fixed;
		top: -100vh;
		left: 0;
		height: 100vh;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: right;
		justify-content: center;
		gap: 1.5rem;
		background-color: var(--mainColor);
		transition: 0.5s;
		-webkit-transition: 0.5s;
		-moz-transition: 0.5s;
		-ms-transition: 0.5s;
		-o-transition: 0.5s;
}

	header .responsive_nav {
		transform: translateY(100vh);
	}

	.nav-btn svg{
		position: relative;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 25px;
		right: 28px;
		padding-top: 0px;
		font-size: 2.5rem;
	}

	.Links {
		font-size: 2rem;
	}
}

/* Add the following CSS to your Navbar.css file */
.Links {
	position: relative;
	transition: all 0.3s ease;
  }
  
  .Links::after {
	content: "";
	position: absolute;
	bottom: -3px;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #0099ff;
	/*Changetodesiredunderlinecolor*/
	transform: scaleX(0);
	transition: transform 0.3s ease;
	-webkit-transform: scaleX(0);
	-moz-transform: scaleX(0);
	-ms-transform: scaleX(0);
	-o-transform: scaleX(0);
}
  
  .Links:hover::after {
	transform: scaleX(1);
  }
  