.ContactCard {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    width: 450px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: solid 1.5px gray;
}

@media(max-width: 768px){
    .ContactCard{
        width: 85vw;
    }
}

.ContactCard:hover{
    border: 1.5px solid #0099ff;
}
  
  .title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  
  .form {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .group {
    position: relative;
  }
  
  .form .group label {
    font-size: 14px;
    color: rgb(99, 102, 102);
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: #fff;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }
  
  .form .group input,
  .form .group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    outline: 0;
    width: 100%;
    background-color: transparent;
  }
  
  .form .group input:-moz-placeholder-shown+ label, .form .group textarea:-moz-placeholder-shown +label {
    top: 10px;
    background-color: transparent;
  }
  
  .form .group input:-ms-input-placeholder+ label, .form .group textarea:-ms-input-placeholder +label {
    top: 10px;
    background-color: transparent;
  }
  
  .form .group input:placeholder-shown+ label, .form .group textarea:placeholder-shown +label {
    top: 10px;
    background-color: transparent;
  }
  
  .form .group input:focus,
  .form .group textarea:focus {
    border-color: #3366cc;
  }
  
  .form .group input:focus+ label, .form .group textarea:focus +label {
    top:-10px;
    left: 10px;
    background-color: #fff;
    color: #3366cc;
    font-weight: 600;
    font-size: 14px;
  }
  
  .form .group textarea {
    resize: none;
    height: 100px;
  }
  
  .form button {
    background-color: #3366cc;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  .form button:hover {
    background-color: #27408b;
  }
  
  